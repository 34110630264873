<template>
  <b-modal
    id="md-change-store"
    hide-footer
    ref="md-change-store"
    title="Thông tin chuyển cửa hàng:"
    no-close-on-backdrop
    size="lg"
  >
    <div class="mt-4">
      <b-table
        class="table-common"
        hover
        bordered
        :items="items"
        :fields="fields"
      >
        <template #cell(applyDate)="row">
          <input
            @change="changeApllyDate(row.item.applyDate)"
            type="date"
            name="begin"
            placeholder="dd-mm-yyyy"
            min="1997-01-01"
            max="2030-12-31"
            v-model="row.item.applyDate"
          />
        </template>
        <template #cell(shiftWork)="row">
          <Autosuggest
            :model="row.item.selectedShiftWork"
            :suggestions="filteredShiftWorkOptions"
            :placeholder="'ca làm việc'"
            :limit="100"
            @select="onSelectedShiftWork(row.item, $event)"
            @change="onInputShiftWorkChange(row.item, $event)"
            suggestionName="suggestionName"
          />
        </template>
        <template #cell(actions)="row">
          <v-icon small class="text-danger" @click="removeForm(row.item)" v-b-tooltip title="Xóa"
            >mdi-delete</v-icon
          >
        </template>
      </b-table>
      <vcl-table
        :speed="5"
        :animate="true"
        :columns="10"
        class="mb-4"
      ></vcl-table>
      <p class="text-warning" v-if="showWarning">Duyệt chuyển ngày hiện tại sẽ thay đổi cửa hàng hiện tại của nhân viên ! </p>

    </div>
    <b-button @click="onsubmit" variant="primary" size="sm" class="mr-3" style="width: 80px">
      <strong>Lưu</strong>
    </b-button>
    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="$bvModal.hide('md-change-store')"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { JOB_TITLE, TRANSFER_STORE_FORM_STATUS } from '../../../utils/enum';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { formatDate } from '@/utils/date';
import { removeAccents } from '@/utils/common';
import moment from 'moment';

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  data() {
    return {
      items: [],
      showWarning: false,
      fields: [
        {
          key: 'employeeId',
          label: 'ID',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          formatter: (value) => {
            return `#${value}`;
          },
        },
        {
          key: 'employeeName',
          label: 'Tên nhân viên',
          thStyle: {
            ...thStyleCommon,
            width: '20%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'targetStoreName',
          label: 'Cửa hàng chuyển đến',
          thStyle: {
            ...thStyleCommon,
            width: '20%',
          },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'applyDate',
          label: 'Ngày chuyển đến',
          thStyle: {
            ...thStyleCommon,
            width: '20%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'shiftWork',
          label: 'Ca làm việc',
          thStyle: {
            ...thStyleCommon,
            width: '20%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle',
        },
      ],
      shiftWorkOptions: [],
      filteredShiftWorkOptions: [],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      responsesSuccess: [],
      responsesFail: [],
    };
  },
  created() {
    this.fetchShiftWork();
  },
  watch: {
    listForm(newValue) {
      this.items = newValue;
    },
  },
  methods: {
    fetchShiftWork: async function () {
      ApiService.setHeader();
      ApiService.get('/shift-work/').then((response) => {
        const listShiftWork = response.data.data;
        this.shiftWorkOptions = listShiftWork.map((element) => {
          return {
            id: element.id,
            name: element.name,
            fromHour: element.fromHour,
            toHour: element.toHour,
            suggestionName: `${element.name} ( ${element.fromHour} - ${element.toHour})`,
          };
        });
        this.filteredShiftWorkOptions = [...this.shiftWorkOptions];
      });
    },
    changeApllyDate(date) {
      if (date == formatDate(moment(), 'YYYY-MM-DD')) {
        this.showWarning = true
      } else {
        this.showWarning = false
      }
      
    },
    removeForm (form) {
      this.items= this.items.filter((item) => {
         return item.id !== form.id
      })
      if(this.items.length == 0){
        this.hideModal()
      }
    },

    onSelectedShiftWork(item, option) {
      item.shiftWorkId = option.item.id;
    },

    onInputShiftWorkChange(item,text) {
      item.selectedShiftWork = text;
     
      const filterData = this.shiftWorkOptions
        .filter((element) => {
          return (
            removeAccents(element.name)
              .toLowerCase()
              .indexOf(removeAccents(item.selectedShiftWork).toLowerCase()) > -1
          );
        })
      this.filteredShiftWorkOptions = [...filterData];
      if (item.selectedShiftWork == '' || item.selectedShiftWork.length ==0 ) {
        this.filteredShiftWorkOptions = [...this.shiftWorkOptions];
      }
      if (!item.selectedShiftWork) {
        item.shiftWorkId = null;
      }
    },

    async onsubmit() {
      const reqData = {
        status: TRANSFER_STORE_FORM_STATUS.APROVED_TRANSFER,
      };
      ApiService.setHeader();
      this.responsesSuccess = [];
      this.responsesFail = [];
      for (const element of this.items) {
        const data = this.getdataUpsertShiftWorkConfig(element);
        Object.assign(reqData, data);
        await ApiService.update('transfer-store-forms', element.id, reqData)
          .then(() => {
            this.responsesSuccess.push(element);
          })
          .catch((error) => {
            makeToastFaile(`Phiếu của ${element.employeeName} : ${error.response.data.message}`);
          })
      };
         
      if (this.responsesSuccess.length) {
        if (this.responsesSuccess.length === this.listForm.length) {
          makeToastSuccess('Cập nhật thành công.');
          setTimeout(() => {
            this.hideModal();
          }, 1000);
        } else {
          let msgResponse = `Cập nhật thành công ${this.responsesSuccess.length} phiếu của:`;
          for (const [i, response] of this.responsesSuccess.entries()) {
            this.removeForm(response)
            if (i !== 0) {
              msgResponse += ', ';
            }
            msgResponse += `${response.employeeName}`;
          }
          makeToastSuccess(msgResponse);
        }
      }
    },

    getEmployInfo(jobTitleId, employeeId, employeeName) {
      switch (jobTitleId) {
        case JOB_TITLE.QL: {
          return {
            managerId: employeeId,
            managerName: employeeName,
          };
        }
        case JOB_TITLE.TL: {
          return {
            assistantId: employeeId,
            assistantName: employeeName,
          };
        }
        case JOB_TITLE.KTBH: {
          return {
            cashierId: employeeId,
            cashierName: employeeName,
          };
        }
        case JOB_TITLE.KTPC: {
          return {
            technicalId: employeeId,
            technicalName: employeeName,
          };
        }
        case JOB_TITLE.KTPM: {
          return {
            technicalId: employeeId,
            technicalName: employeeName,
          };
        }
        case JOB_TITLE.CDPK: {
          return {
            accessoryStaffId: employeeId,
            accessoryStaffName: employeeName,
          };
        }
        case JOB_TITLE.TDKH: {
          return {
            customerReceptionId: employeeId,
            customerReceptionName: employeeName,
          };
        }
        default: {
          return {};
        }
      }
    },

    getdataUpsertShiftWorkConfig(element) {
      const employeeInfo = this.getEmployInfo(
        element.jobTitleId,
        element.employeeId,
        element.employeeName
      );
      return {
        ...employeeInfo,
        storeId: element.targetStoreId,
        shiftWorkId: element.shiftWorkId,
        applyDate: formatDate(element.applyDate, 'YYYY-MM-DD'),
      };
    },

    hideModal() {
      this.$emit('fetchTransferStoreForms');
      this.$bvModal.hide('md-change-store');
    },
  },
  props: ['listForm'],

  components: {
    Autosuggest,
  },
};
</script>

<style scoped>
.table-common /deep/ .vue-treeselect__input-container {
  padding-top: 0px;
}
.table-common /deep/ .vue-treeselect__control {
  border-radius: none;
  border: none;
  height: 2.5rem;
}

.table-common /deep/ .vue-treeselect__input {
  font-size: 22px;
}
</style>
